// *********************
//  Inputs
// *********************

// Input group
.input-group {
  &--cta {
    margin-top: rem(48);
  }
}
// Text
.input {
  display: block;
  font-size: var(--text-xs);
  width: 100%;
  height: rem(46);
  border: none;
  border: 1px solid $border-color;
  outline: none;
  padding: 0 rem(16);
  border-radius: rem(4);

  &::placeholder {
    font-size: var(--text-xs);
  }

  &__label {
    display: inline-block;
    font-size: var(--text-xs);
    color: $secondary-color;
    margin-bottom: rem(8);
  }

  &__label--required,
  &__label--note {
    font-size: rem(11);
    margin-left: rem(4);
  }

  &__label--required {
    color: $invalid-color;
  }

  &__label--note {
    display: block;
    color: $primary-color;
  }

  &:read-only,
  &:disabled {
    color: #757575;
  }

  &:focus {
    border-color: $primary-color;
  }

  &-margin {
    margin-bottom: rem(24);
  }
}

// Textarea
textarea.input {
  height: rem(150);
}

// Color
input[type="color"].input {
  width: rem(50);
  padding: 0;
  border: 0;
  cursor: pointer;
}

// Flag
.input-flag {
  position: relative;
  display: inline-block;

  label {
    display: inline-block;
    cursor: pointer;
  }

  label svg {
    filter: grayscale(100%);
    opacity: 0.6;
    transition: all 0.1s linear;
  }

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  input:checked + label svg {
    filter: grayscale(0);
    opacity: 1;
  }
}

// Select
.select {
  position: relative;
  border-radius: rem(5);
  width: 100%;
  overflow: hidden;
  z-index: 999999;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(40);
    padding: 0 rem(16);
    background: $secondary-color;
    z-index: 3;
    cursor: pointer;
  }

  &__label {
    display: flex;
    align-items: center;
  }

  &__label .icon {
    color: $light-color;
  }

  &__label-wrapper {
    display: flex;
    align-items: center;
    height: rem(36);
    margin-right: rem(16);
    border-top-left-radius: rem(3);
    border-bottom-left-radius: rem(3);
  }

  &__text {
    font-size: var(--text-xs);
    font-weight: 500;
    color: $light-color;
  }

  &__icon {
    color: $light-color;
  }

  &__body {
    position: absolute;
    left: 0;
    width: 100%;
    box-shadow: 2px 5px 6px 0px #72727236;
    z-index: 999999;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-50px);
  }

  &__search {
    font-size: var(--text-xs);
    height: rem(40);
    border: none;
    border-left: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    border-bottom: 1px solid $border-color;
    outline: none;
    padding: 0 rem(8);
    width: 100%;
    background: #ffffff;
  }

  &__list {
    width: 100%;
    max-height: rem(209);
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    border-left: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
    box-shadow: 2px 5px 4px 0 #7272729c;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondary-color;
    }

    &::-webkit-scrollbar-track {
      background: rgb(235, 235, 235);
    }
  }

  &__list li {
    display: flex;
    align-items: center;
    font-size: var(--text-xs);
    height: rem(38);
    cursor: pointer;
    padding: 0 rem(8);
    background: #ffffff;
    border-left: 2px solid transparent;
    transition: all 0.2s ease-in-out;
  }

  &__list li:nth-child(odd) {
    background: #fafafa;
  }

  &__list li:hover {
    padding-left: rem(16);
    border-color: $border-color;
  }

  &__list .icon {
    margin-right: rem(12);
  }

  // Open select
  &.js-select--open {
    overflow: visible;

    .select__body {
      pointer-events: auto;
      visibility: visible;
      opacity: 1;
      transform: translateY(0px);
      transition: opacity 0.2s linear,
        transform 0.3s cubic-bezier(0, 0.37, 0, 1.23);
    }

    .select__icon {
      transform: rotate(180deg);
    }
  }
}

//Input type search
.input__group--search {
  position: relative;

  .input {
    min-width: rem(200);
    height: rem(40);
    border: none;
    border-bottom: 1px solid $border-color;
    outline: none;
    padding: 0 rem(16);
    border-radius: 0;
  }

  .input::placeholder {
    font-size: var(--text-xs);
  }

  .input__icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(36);
    width: rem(36);
    border: none;
    padding: 0;
    margin: 0 rem(8) 0 0;
    cursor: pointer;
  }

  .input__icon svg {
    color: $border-color;
    width: rem(36);
  }
}

//Input type checkbox tab
.checkbox-tab {
  display: block;
  position: relative;
  cursor: pointer;
  background: $light-color;
  margin-bottom: rem(12);

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    overflow: hidden;
  }

  &__wrapper::before {
    content: "";
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: rem(70);
    background: $valid-color;
    opacity: 0.5;
    transform: scale(1.5);
    z-index: 99;
  }

  &__wrapper::after {
    content: inline-svg(
      "<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='#FFFFFF' viewBox='0 0 512 512'><path d='M369.164,174.77a20,20,0,0,1,0,28.285L234.992,337.231a20,20,0,0,1-28.285,0l-63.871-63.875a20,20,0,0,1,28.281-28.281l49.73,49.731L340.878,174.77a20,20,0,0,1,28.285,0ZM512,256c0,141.5-114.516,256-256,256C114.5,512,0,397.484,0,256,0,114.5,114.516,0,256,0,397.5,0,512,114.516,512,256Zm-40,0A216,216,0,1,0,256,472,215.88,215.88,0,0,0,472,256Zm0,0' transform='translate(0 0)'/></svg>"
    );
    visibility: hidden;
    position: absolute;
    top: rem(19);
    left: rem(19);
    height: rem(32);
    width: rem(32);
    transform: scale(1.5);
    z-index: 999;
  }

  &__images {
    width: rem(70);
    height: rem(70);
    object-fit: cover;
  }

  &__text {
    font-size: var(--text-xs);
    width: 100%;
    text-align: center;
  }

  input {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
  }

  input:checked + &__wrapper {
    border-color: $valid-color;
  }

  input:checked + &__wrapper::before,
  input:checked + &__wrapper::after {
    visibility: visible;
    transform: scale(1);
    transition: transform 0.1s ease-in-out;
  }

  input:checked + &__wrapper img {
    filter: grayscale(100%);
  }

  &:hover &__wrapper {
    border-color: $valid-color;
    transition: all 0.2s linear;
  }
}

// Input toggle

@mixin inactiveMixin {
  content: "";
  position: absolute;
  display: block;
}

@mixin beforeAnimation {
  -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
}

@mixin afterAnimation {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, 0.04),
    0 4px 9px hsla(0, 0%, 0%, 0.13), 0 3px 3px hsla(0, 0%, 0%, 0.05);
  -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}

// Mobile Toggle Switch
.toggle {
  display: flex;
  align-items: center;

  input {
    opacity: 0; // hides checkbox
    position: absolute;
  }

  input:checked + &__icon:before {
    background: $valid-color; // Active Color
    -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  }

  input:checked + &__icon:after {
    left: 39px - 20px;
  }

  input + &__icon {
    position: relative;
    display: inline-block;
    user-select: none;
    -moz-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    -webkit-tap-highlight-color: transparent;
    height: 20px;
    width: 35px;
    border: 1px solid #e4e4e4;
    border-radius: 60px;
    &:before {
      @include inactiveMixin;
      @include beforeAnimation;
      height: 20px;
      width: 35px;
      top: 0;
      left: 0;
      border-radius: 30px;
    }
    &:after {
      @include inactiveMixin;
      @include afterAnimation;
      background: #f5f5f5;
      height: 18px;
      width: 18px;
      top: 0.5px;
      left: 0px;
      border-radius: 60px;
    }
  }

  &__icon {
    cursor: pointer;
  }

  &__text {
    font-size: var(--text-xs);
    margin-left: rem(12);
  }
}

// checkbox
.checkbox {
  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="radio"]:not(:checked),
  [type="checkbox"]:checked,
  [type="radio"]:checked {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:not(:checked) + label,
  [type="radio"]:not(:checked) + label,
  [type="checkbox"]:checked + label,
  [type="radio"]:checked + label {
    position: relative;
    padding-left: rem(32);
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="radio"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before,
  [type="radio"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: rem(20);
    height: rem(20);
    border: 2px solid #e4e4e4;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="radio"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after,
  [type="radio"]:checked + label:after {
    content: inline-svg(
      "<svg xmlns='http://www.w3.org/2000/svg' fill='#144672' width='10px' viewBox='0 0 78.37 57.484'> <g transform='translate(0 -10.443)'> <path d='M78.049,19.015,29.458,67.606a1.094,1.094,0,0,1-1.548,0L.32,40.015a1.094,1.094,0,0,1,0-1.547l6.7-6.7a1.1,1.1,0,0,1,1.548,0L28.685,51.876,69.8,10.763a1.1,1.1,0,0,1,1.548,0l6.7,6.7A1.094,1.094,0,0,1,78.049,19.015Z'/> </g></svg>"
    );
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: rem(-1);
    left: rem(0.5);
    width: rem(20);
    height: rem(20);
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after,
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  [type="checkbox"]:checked + label:after,
  [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }

  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="radio"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before,
  [type="radio"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #144672;
    background-color: #144672;
  }

  [type="checkbox"]:disabled:checked + label:after,
  [type="radio"]:disabled:checked + label:after {
    color: #144672;
  }

  [type="checkbox"]:disabled + label,
  [type="radio"]:disabled + label {
    color: #144672;
  }

  /* accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="radio"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before,
  [type="radio"]:not(:checked):focus + label:before {
    border: 2px solid #144672;
  }

  [type="checkbox"]:checked + label:before,
  [type="radio"]:checked + label:before {
    border-color: #144672;
  }
}
