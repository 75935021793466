.accordion {
	margin: auto;
    max-width: rem(400);
    width: 100%;
}

.accordion input {
	display: none;
}

.box {
	position: relative;
	background: white;
    height: rem(32);
    transition: all .15s ease-in-out;
}

.box::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    // box-shadow: 0 -1px 0 #e5e5e5,0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24);
}

.box-header {
	background: #144672;
	z-index: 100;
	cursor: initial;
	padding: rem(10) rem(0);
	margin-bottom: rem(12);
	// box-shadow: 0 -1px 0 #e5e5e5,0 0 2px -2px rgba(0,0,0,.12),0 2px 4px -4px rgba(0,0,0,.24);
}

.box-header  .box-title {
	margin: 0;
	font-weight: normal;
	font-size: var(--text-sm);
	color: #ffffff;
	cursor: initial;
}

.box-title {
	width: calc(100% - 40px);
	height: rem(32);
	line-height: rem(32);
	padding: 0 rem(20);
	font-size: var(--text-xs);
	display: inline-block;
	cursor: pointer;
	-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;
}

.box-content {
	width: calc(100% - 40px);
	padding: rem(15) rem(20);
	font-size: var(--text-sm);
	color: rgba(0,0,0,.54);
	display: none;
}

.box-close {
	position: absolute;
	height: rem(32);
	width: 100%;
	top: 0;
	left: 0;
	cursor: pointer;
	display: none;
}

input:checked + .box {
	height: auto;
	margin: var(--text-sm) 0;
    // box-shadow: 0 0 6px rgba(0,0,0,.16),0 6px 12px rgba(0,0,0,.32);
}

input:checked + .box .box-title {
	border-bottom: 1px solid rgba(0,0,0,.18);
}

input:checked + .box .box-content,
input:checked + .box .box-close {
	display: inline-block;
}

.arrows section .box-title {
	padding-left: rem(44);
	width: calc(100% - 64px);
}

.arrows section .box-title:before {
	position: absolute;
	display: block;
	content: '\203a';
	font-size: var(--text-sm);
	left: rem(20);
	top: rem(-2);
	transition: transform .15s ease-in-out;
	color: rgba(0,0,0,.54);
}

input:checked + section.box .box-title:before {
	transform: rotate(90deg);
}
