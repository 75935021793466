// *********************
// Typography
// *********************

:root {
    --font-primary: 'Montserrat' !important;
    --font-secondary: serif;

    /* set base values */
    --text-base-size: 1rem;
    --text-scale-ratio: 1.2;

    /* type scale */
    --text-xs: calc(1.2rem / (var(--text-scale-ratio) * var(--text-scale-ratio)));
    --text-sm: calc(1.2rem / var(--text-scale-ratio));
    --text-md: calc(1.2rem * var(--text-scale-ratio));
    --text-lg: calc(1.2rem * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xl: calc(1.2rem * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xxl: calc(1.2rem * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xxxl: calc(1.2rem * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));

    /* line-height */
    --heading-line-height: 1.2;
    --body-line-height: 1.6;

    --baseline: 24px;
  
    /* spacing values */
    --space-sm:   calc(var(--baseline)/2);
    --space-md:   var(--baseline);
    --space-lg:   calc(var(--baseline)*2);
    --space-xl:   calc(var(--baseline)*3);
}

// @include media-breakpoint-up(lg) {
//     :root {
//         --text-base-size: 1.25rem;
//         --text-scale-ratio: 1.25;
//     }
// }

html {
    font-size: 100%;
    font-style: normal;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    font-size: var(--text-base-size);
    font-family: var(--font-primary);
    font-weight: 500;
    font-style: normal;
    overflow-x: hidden;
    // overflow: hidden;
}

/* text size */
.text--xxxl {
    font-size: var(--text-xxxl);
}

h1, .text--xxl {
    font-size: var(--text-xxl);
}

h2, .text--xl {
    font-size: var(--text-xl);
}

h3, .text--lg {
    font-size: var(--text-lg);
}

h4, .text--md {
    font-size: var(--text-md);
}

.text--sm, small {
    font-size: var(--text-sm);
}

.text--xs {
    font-size: var(--text-xs);
}

/* text weight | regular is default */
.w--light {
    font-weight: 100;
}

.w--medium {
    font-weight: 500;
}

.w--bold {
    font-weight: bold;
}

.w--black {
    font-weight: 900;
}