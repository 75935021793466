// *********************
//  Sidebar
// *********************
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: #fafafa;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(60);
    width: rem(50);

    @media only screen and (max-width: $large-device) {
      width: rem(50);
    }
  }

  &__logo img {
    height: rem(40);
    width: rem(40);
    object-fit: contain;
  }

  // Panel style

  &__nav {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: calc(100% - 60px);
    width: rem(50);
    background: $sidebar-color;
    z-index: 3;

    @media only screen and (max-width: $large-device) {
      width: rem(50);
    }
  }

  &__nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &__nav ul:last-child {
    margin-top: auto;

    @media only screen and (max-width: $large-device) {
      // display: none;
    }
  }

  &__nav ul li {
    width: 100%;
  }

  &__nav-link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(45);
  }

  &__nav-link::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: $primary-color;
    height: 100%;
    width: 0;
    transition: width 0.2s ease-in-out;
  }

  &__nav-link p {
    font-size: var(--text-xs);
    color: #888888;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 100%;
    white-space: nowrap;
    margin: 0;
    height: 100%;
    background: $sidebar-color;
    padding: rem(8) rem(14);
  }

  &__nav-link:hover p {
    display: flex;
  }

  &__nav-link:hover::after {
    width: rem(3);
  }

  &__nav ul svg {
    color: #888888;
    width: rem(16);
    height: rem(16);
    object-fit: contain;
    max-width: 100%;
  }

  &__nav-link:hover svg {
    color: #ffffff;
  }

  // Tab style

  &__wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    // max-height: calc(100% - 80px);
    max-height: 100%;
    padding: rem(48) rem(24);

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondary-color;
    }

    &::-webkit-scrollbar-track {
      background: rgb(235, 235, 235);
    }
  }

  &__tab {
    position: absolute;
    top: rem(60);
    left: rem(50);
    height: calc(100% - 60px);
    width: rem(350);
    padding: 0;
    background: #ffffff;
    box-shadow: 2px 5px 4px 0px #7272729c;
    z-index: 2;

    transform: translateX(-100px);
    opacity: 0;
    visibility: hidden;

    @media only screen and (max-width: $large-device) {
      left: rem(50);
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: rem(-48);
    width: rem(48);
    height: rem(48);
    background: #ffffff;
    // border-top: 1px solid #DADADA;
    // border-right: 1px solid #DADADA;
    // border-bottom: 1px solid #DADADA;
    box-shadow: 0px 0px 4px 0px #7272729c;
    cursor: pointer;
  }

  &__close svg {
    width: rem(24);
    height: rem(24);
    color: $secondary-color;
  }

  &__heading {
    font-size: var(--text-sm);
    color: $secondary-color;
    margin-top: 0;
  }

  &__tab--description {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul li {
      display: flex;
      align-items: center;
      font-size: var(--text-xs);
      border-top: 1px solid $border-color;
      padding: rem(12) rem(4);
    }

    ul li:last-child {
      border-bottom: 1px solid $border-color;
    }

    ul li .icon {
      margin-right: rem(16);
    }
  }

  &__tab--map {
    .input-group {
      margin-bottom: rem(12);
    }
  }

  .js-tab--open {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
    transition: all 0.4s cubic-bezier(0, 0.99, 0, 0.99);
  }
}
