// *********************
//  Header
// *********************
.header {
    position: relative;
    // display: flex;
    // align-items: center;
    height: rem(60);
    background: $header-color;
    margin-left: rem(50);
    padding: 0 rem(24);
    // box-shadow: 0px 2px 24px #DADADA;
    // margin-bottom: rem(64);
    z-index: 99999;

    @media only screen and (max-width: $large-device) {
        margin-left: rem(50);
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: rem(-2);
        height: rem(60);
        width: rem(2);
        border-left: 2px solid $border-color;
    }

    &__account {
        display: none;
        align-items: center;
        position: absolute;
        top: rem(18);
        left: rem(24);
        // transform: translateY(-50%);
        z-index: 9999;

        @media only screen and (max-width: $large-device) {
            display: flex;
        }
    }

    &__account a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $sidebar-color;
        width: rem(24);
        height: rem(24);
        margin-right: rem(16);
    }

    &__account svg {
        width: rem(24);
        height: rem(24);
    }

    &__menu {
        display: none;
        align-items: center;
        position: absolute;
        top: rem(22.5);
        right: rem(24);
        height: rem(15);
        width: rem(24);
        cursor: pointer;
        z-index: 9999;

        @media only screen and (max-width: $large-device) {
            display: flex;
        }
    }

    &__menu svg {
        color: $sidebar-color;
        height: auto;
        width: 100%;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        height: 100%;

        @media only screen and (max-width: $large-device) {
            padding-top: rem(60);
            padding-bottom: rem(20);
            flex-wrap: wrap;
            overflow: hidden;
        }
    }

    &__header {
        display: flex;
        flex-grow: 1;
        margin-right: rem(24);
        padding-right: rem(24);
        border-right: 1px solid $border-color;

        
        @media only screen and (max-width: $large-device) {
            flex-wrap: wrap;
            margin-right: 0;
            padding-right: 0;
            padding-top: rem(20);
            border-right: none;
            border-top: 1px solid $border-color;
            width: 100%;

            .input__group {
                width: 100%;
            }
            
            .input__group:first-child {
                margin-bottom: rem(24);
                order: 2;
            }

            .input__group:first-child input {
                padding-right: rem(48);
                width: 100%;
            }

            .input__group:last-child {
                order: 1;
            }
        }
    }
    
    &__header .input__group:not(:last-child) {
        margin-right: rem(24);

        
        @media only screen and (max-width: $large-device) {
            margin-right: 0;
        }
    }

    &__body {
        display: flex;
        flex-grow: 3;

        @media only screen and (max-width: $large-device) {
            flex-wrap: wrap;

            .input__group {
                width: 100%;
                margin-top: rem(12);
            }
        }
    }

    &__body .input__group:not(:last-child) {
        margin-right: 2%;

        @media only screen and (max-width: $large-device) {
            margin-right: 0;
        }
    }

    // Open mobile 
    &.js-header--open {
        @media only screen and (max-width: $large-device) {
            height: auto;

            .header__wrapper {
                overflow: visible;
            }
        }
    }
}

