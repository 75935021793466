@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-MediumItalic.eot");
  src: url("../fonts/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-MediumItalic.woff2") format("woff2"), url("../fonts/Montserrat-MediumItalic.woff") format("woff"), url("../fonts/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BlackItalic.eot");
  src: url("../fonts/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-BlackItalic.woff2") format("woff2"), url("../fonts/Montserrat-BlackItalic.woff") format("woff"), url("../fonts/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.eot");
  src: url("../fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff"), url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BoldItalic.eot");
  src: url("../fonts/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-BoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-BoldItalic.woff") format("woff"), url("../fonts/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.eot");
  src: url("../fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Black.woff2") format("woff2"), url("../fonts/Montserrat-Black.woff") format("woff"), url("../fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-LightItalic.eot");
  src: url("../fonts/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-LightItalic.woff2") format("woff2"), url("../fonts/Montserrat-LightItalic.woff") format("woff"), url("../fonts/Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.eot");
  src: url("../fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

.color__text--primary {
  color: #45bbf0; }

.color__bg--primary {
  background: #45bbf0; }

.color__text--secondary {
  color: #144672; }

.color__bg--secondary {
  background: #144672; }

.color__text--dark {
  color: #222222; }

.color__bg--dark {
  background: #222222; }

.color__text--light {
  color: #fbfbfb; }

.color__bg--light {
  background: #fbfbfb; }

/* Document
 * ========================================================================== */
/**
 * Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box; }

/**
  * 1. Add text decoration inheritance in all browsers (opinionated).
  * 2. Add vertical alignment inheritance in all browsers (opinionated).
  */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
  * 1. Use the default cursor in all browsers (opinionated).
  * 2. Change the line height in all browsers (opinionated).
  * 3. Use a 4-space tab width in all browsers (opinionated).
  * 4. Remove the grey highlight on links in iOS (opinionated).
  * 5. Prevent adjustments of font size after orientation changes in
  *    IE on Windows Phone and in iOS.
  * 6. Breaks words to prevent overflow in all browsers (opinionated).
  */
html {
  cursor: default;
  /* 1 */
  line-height: 1.5;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  tab-size: 4;
  /* 3 */
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
  * ========================================================================== */
/**
  * Remove the margin in all browsers (opinionated).
  */
body {
  margin: 0; }

/**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Edge, Firefox, and Safari.
  */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
  * ========================================================================== */
/**
  * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
  */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0; }

/**
  * Remove the margin on nested lists in Edge 18- and IE.
  */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0; }

/**
  * 1. Add the correct sizing in Firefox.
  * 2. Show the overflow in Edge 18- and IE.
  */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
  * Add the correct display in IE.
  */
main {
  display: block; }

/**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */
nav ol,
nav ul {
  list-style: none;
  padding: 0; }

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
  * ========================================================================== */
/**
  * Remove the gray background on active links in IE 10.
  */
a {
  background-color: transparent; }

/**
  * Add the correct text decoration in Edge 18-, IE, and Safari.
  */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */
b,
strong {
  font-weight: bolder; }

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
  * Add the correct font size in all browsers.
  */
small {
  font-size: 80%; }

/* Embedded content
  * ========================================================================== */
/*
  * Change the alignment on media elements in all browsers (opinionated).
  */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
  * Add the correct display in IE 9-.
  */
audio,
video {
  display: inline-block; }

/**
  * Add the correct display in iOS 4-7.
  */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
  * Remove the border on iframes in all browsers (opinionated).
  */
iframe {
  border-style: none; }

/**
  * Remove the border on images within links in IE 10-.
  */
img {
  border-style: none; }

/**
  * Change the fill color to match the text color in all browsers (opinionated).
  */
svg:not([fill]) {
  fill: currentColor; }

/**
  * Hide the overflow in IE.
  */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
  * ========================================================================== */
/**
  * Collapse border spacing in all browsers (opinionated).
  */
table {
  border-collapse: collapse; }

/* Forms
  * ========================================================================== */
/**
  * Remove the margin on controls in Safari.
  */
button,
input,
select {
  margin: 0; }

/**
  * 1. Show the overflow in IE.
  * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
  */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
  * Correct the inability to style buttons in iOS and Safari.
  */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
  * 1. Change the inconsistent appearance in all browsers (opinionated).
  * 2. Correct the padding in Firefox.
  */
fieldset {
  border: 1px solid #a0a0a0;
  /* 1 */
  padding: 0.35em 0.75em 0.625em;
  /* 2 */ }

/**
  * Show the overflow in Edge 18- and IE.
  */
input {
  overflow: visible; }

/**
  * 1. Correct the text wrapping in Edge 18- and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
  * 1. Add the correct display in Edge 18- and IE.
  * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
  */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
  * Remove the inheritance of text transform in Firefox.
  */
select {
  text-transform: none; }

/**
  * 1. Remove the margin in Firefox and Safari.
  * 2. Remove the default vertical scrollbar in IE.
  * 3. Change the resize direction in all browsers (opinionated).
  */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
  * Remove the padding in IE 10-.
  */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
  * 1. Correct the odd appearance in Chrome, Edge, and Safari.
  * 2. Correct the outline style in Safari.
  */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
  * Correct the cursor style of increment and decrement buttons in Safari.
  */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
  * Correct the text style of placeholders in Chrome, Edge, and Safari.
  */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
  * Remove the inner padding in Chrome, Edge, and Safari on macOS.
  */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
  * 1. Correct the inability to style upload buttons in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
  * Remove the inner border and padding of focus outlines in Firefox.
  */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
  * Restore the focus outline styles unset by the previous rule in Firefox.
  */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
  * Remove the additional :invalid styles in Firefox.
  */
:-moz-ui-invalid {
  box-shadow: none; }

/* Interactive
  * ========================================================================== */
/*
  * Add the correct display in Edge 18- and IE.
  */
details {
  display: block; }

/*
  * Add the correct styles in Edge 18-, IE, and Safari.
  */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
  * Add the correct display in all browsers.
  */
summary {
  display: list-item; }

/* Scripting
  * ========================================================================== */
/**
  * Add the correct display in IE 9-.
  */
canvas {
  display: inline-block; }

/**
  * Add the correct display in IE.
  */
template {
  display: none; }

/* User interaction
  * ========================================================================== */
/*
  * 1. Remove the tapping delay in IE 10.
  * 2. Remove the tapping delay on clickable elements
       in all browsers (opinionated).
  */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
  * Add the correct display in IE 10-.
  */
[hidden] {
  display: none; }

/* Accessibility
  * ========================================================================== */
/**
  * Change the cursor on busy elements in all browsers (opinionated).
  */
[aria-busy="true"] {
  cursor: progress; }

/*
  * Change the cursor on control elements in all browsers (opinionated).
  */
[aria-controls] {
  cursor: pointer; }

/*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements in all browsers (opinionated).
  */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
  * Change the display on visually hidden accessible elements
  * in all browsers (opinionated).
  */
[aria-hidden="false"][hidden] {
  display: initial; }

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute; }

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Add typography inheritance in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  /* 1 */
  border: 1px solid WindowFrame;
  /* 1 */
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  letter-spacing: inherit;
  /* 2 */
  padding: 0.25em 0.375em;
  /* 1 */ }

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: no-repeat right center / 1em;
  border-radius: 0;
  padding-right: 1em; }

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */
select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E"); }

/**
 * Change the inconsistent appearance in IE (opinionated).
 */
::-ms-expand {
  display: none; }

/**
 * Correct the inconsistent appearance in IE (opinionated).
 */
:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.54); }

/**
 * Use the default user interface font in all browsers (opinionated).
 */
html {
  font-family: system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

/**
   * Use the default monospace user interface font in all browsers (opinionated).
   */
code,
kbd,
samp,
pre {
  font-family: "Menlo", "Consolas", "Roboto Mono", "Ubuntu Monospace", "Noto Mono", "Oxygen Mono", "Liberation Mono", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

:root {
  --font-primary: 'Montserrat' !important;
  --font-secondary: serif;
  /* set base values */
  --text-base-size: 1rem;
  --text-scale-ratio: 1.2;
  /* type scale */
  --text-xs: calc(1.2rem / (var(--text-scale-ratio) * var(--text-scale-ratio)));
  --text-sm: calc(1.2rem / var(--text-scale-ratio));
  --text-md: calc(1.2rem * var(--text-scale-ratio));
  --text-lg: calc(1.2rem * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xl: calc(1.2rem * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xxl: calc(1.2rem * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xxxl: calc(1.2rem * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  /* line-height */
  --heading-line-height: 1.2;
  --body-line-height: 1.6;
  --baseline: 24px;
  /* spacing values */
  --space-sm:   calc(var(--baseline)/2);
  --space-md:   var(--baseline);
  --space-lg:   calc(var(--baseline)*2);
  --space-xl:   calc(var(--baseline)*3); }

html {
  font-size: 100%;
  font-style: normal;
  padding: 0;
  scroll-behavior: smooth; }

body {
  font-size: var(--text-base-size);
  font-family: var(--font-primary);
  font-weight: 500;
  font-style: normal;
  overflow-x: hidden; }

/* text size */
.text--xxxl {
  font-size: var(--text-xxxl); }

h1, .text--xxl {
  font-size: var(--text-xxl); }

h2, .text--xl {
  font-size: var(--text-xl); }

h3, .text--lg {
  font-size: var(--text-lg); }

h4, .text--md {
  font-size: var(--text-md); }

.text--sm, small {
  font-size: var(--text-sm); }

.text--xs {
  font-size: var(--text-xs); }

/* text weight | regular is default */
.w--light {
  font-weight: 100; }

.w--medium {
  font-weight: 500; }

.w--bold {
  font-weight: bold; }

.w--black {
  font-weight: 900; }

.icon {
  width: 1.25rem;
  height: 1.25rem; }

.header {
  position: relative;
  height: 3.75rem;
  background: #f2f2f2;
  margin-left: 3.125rem;
  padding: 0 1.5rem;
  z-index: 99999; }
  @media only screen and (max-width: 75em) {
    .header {
      margin-left: 3.125rem; } }
  .header::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -0.125rem;
    height: 3.75rem;
    width: 0.125rem;
    border-left: 2px solid #dbdbdb; }
  .header__account {
    display: none;
    align-items: center;
    position: absolute;
    top: 1.125rem;
    left: 1.5rem;
    z-index: 9999; }
    @media only screen and (max-width: 75em) {
      .header__account {
        display: flex; } }
  .header__account a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222222;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem; }
  .header__account svg {
    width: 1.5rem;
    height: 1.5rem; }
  .header__menu {
    display: none;
    align-items: center;
    position: absolute;
    top: 1.40625rem;
    right: 1.5rem;
    height: 0.9375rem;
    width: 1.5rem;
    cursor: pointer;
    z-index: 9999; }
    @media only screen and (max-width: 75em) {
      .header__menu {
        display: flex; } }
  .header__menu svg {
    color: #222222;
    height: auto;
    width: 100%; }
  .header__wrapper {
    display: flex;
    align-items: center;
    height: 100%; }
    @media only screen and (max-width: 75em) {
      .header__wrapper {
        padding-top: 3.75rem;
        padding-bottom: 1.25rem;
        flex-wrap: wrap;
        overflow: hidden; } }
  .header__header {
    display: flex;
    flex-grow: 1;
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid #dbdbdb; }
    @media only screen and (max-width: 75em) {
      .header__header {
        flex-wrap: wrap;
        margin-right: 0;
        padding-right: 0;
        padding-top: 1.25rem;
        border-right: none;
        border-top: 1px solid #dbdbdb;
        width: 100%; }
        .header__header .input__group {
          width: 100%; }
        .header__header .input__group:first-child {
          margin-bottom: 1.5rem;
          order: 2; }
        .header__header .input__group:first-child input {
          padding-right: 3rem;
          width: 100%; }
        .header__header .input__group:last-child {
          order: 1; } }
  .header__header .input__group:not(:last-child) {
    margin-right: 1.5rem; }
    @media only screen and (max-width: 75em) {
      .header__header .input__group:not(:last-child) {
        margin-right: 0; } }
  .header__body {
    display: flex;
    flex-grow: 3; }
    @media only screen and (max-width: 75em) {
      .header__body {
        flex-wrap: wrap; }
        .header__body .input__group {
          width: 100%;
          margin-top: 0.75rem; } }
  .header__body .input__group:not(:last-child) {
    margin-right: 2%; }
    @media only screen and (max-width: 75em) {
      .header__body .input__group:not(:last-child) {
        margin-right: 0; } }
  @media only screen and (max-width: 75em) {
    .header.js-header--open {
      height: auto; }
      .header.js-header--open .header__wrapper {
        overflow: visible; } }

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: #fafafa; }
  .sidebar__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.75rem;
    width: 3.125rem; }
    @media only screen and (max-width: 75em) {
      .sidebar__logo {
        width: 3.125rem; } }
  .sidebar__logo img {
    height: 2.5rem;
    width: 2.5rem;
    object-fit: contain; }
  .sidebar__nav {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: calc(100% - 60px);
    width: 3.125rem;
    background: #222222;
    z-index: 3; }
    @media only screen and (max-width: 75em) {
      .sidebar__nav {
        width: 3.125rem; } }
  .sidebar__nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%; }
  .sidebar__nav ul:last-child {
    margin-top: auto; }
  .sidebar__nav ul li {
    width: 100%; }
  .sidebar__nav-link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.8125rem; }
  .sidebar__nav-link::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: #45bbf0;
    height: 100%;
    width: 0;
    transition: width 0.2s ease-in-out; }
  .sidebar__nav-link p {
    font-size: var(--text-xs);
    color: #888888;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 100%;
    white-space: nowrap;
    margin: 0;
    height: 100%;
    background: #222222;
    padding: 0.5rem 0.875rem; }
  .sidebar__nav-link:hover p {
    display: flex; }
  .sidebar__nav-link:hover::after {
    width: 0.1875rem; }
  .sidebar__nav ul svg {
    color: #888888;
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    max-width: 100%; }
  .sidebar__nav-link:hover svg {
    color: #ffffff; }
  .sidebar__wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    padding: 3rem 1.5rem; }
    .sidebar__wrapper::-webkit-scrollbar {
      width: 5px; }
    .sidebar__wrapper::-webkit-scrollbar-thumb {
      background: #144672; }
    .sidebar__wrapper::-webkit-scrollbar-track {
      background: #ebebeb; }
  .sidebar__tab {
    position: absolute;
    top: 3.75rem;
    left: 3.125rem;
    height: calc(100% - 60px);
    width: 21.875rem;
    padding: 0;
    background: #ffffff;
    box-shadow: 2px 5px 4px 0px #7272729c;
    z-index: 2;
    transform: translateX(-100px);
    opacity: 0;
    visibility: hidden; }
    @media only screen and (max-width: 75em) {
      .sidebar__tab {
        left: 3.125rem; } }
  .sidebar__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -3rem;
    width: 3rem;
    height: 3rem;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px #7272729c;
    cursor: pointer; }
  .sidebar__close svg {
    width: 1.5rem;
    height: 1.5rem;
    color: #144672; }
  .sidebar__heading {
    font-size: var(--text-sm);
    color: #144672;
    margin-top: 0; }
  .sidebar__tab--description ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .sidebar__tab--description ul li {
    display: flex;
    align-items: center;
    font-size: var(--text-xs);
    border-top: 1px solid #dbdbdb;
    padding: 0.75rem 0.25rem; }
  .sidebar__tab--description ul li:last-child {
    border-bottom: 1px solid #dbdbdb; }
  .sidebar__tab--description ul li .icon {
    margin-right: 1rem; }
  .sidebar__tab--map .input-group {
    margin-bottom: 0.75rem; }
  .sidebar .js-tab--open {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
    transition: all 0.4s cubic-bezier(0, 0.99, 0, 0.99); }

.content--layout {
  position: relative;
  margin-left: 3.125rem;
  height: calc(100vh - 60px);
  overflow: hidden; }

.splitter {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh + 150px); }
  .splitter .gutter.gutter-vertical {
    z-index: 9;
    background-color: #144672; }
  .splitter__header {
    display: flex;
    position: relative;
    background: #FFFFFF;
    flex-wrap: wrap;
    z-index: 9; }
  .splitter__header .input__group--search {
    flex-grow: 1;
    min-width: 18.75rem;
    background: #f6f6f6; }
    @media only screen and (max-width: 48em) {
      .splitter__header .input__group--search {
        order: 2; } }
  .splitter__records {
    font-size: var(--text-xs);
    color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    background: #FFFFFF; }
  .splitter__records-count {
    color: #a4a4a4;
    margin-left: 0.75rem; }
  .splitter__tab {
    display: flex;
    list-style: none;
    font-size: var(--text-xs);
    padding: 0;
    margin: 0;
    flex-shrink: 0; }
    @media only screen and (max-width: 48em) {
      .splitter__tab {
        justify-content: center;
        width: 100%;
        border-bottom: 1px solid #e4e4e4; } }
  @media only screen and (max-width: 48em) {
    .splitter__tab:nth-child(1) {
      order: 1; } }
  @media only screen and (max-width: 48em) {
    .splitter__tab:nth-child(2) {
      order: 4;
      width: auto; } }
  .splitter__tab--controllers {
    margin-left: auto; }
    @media only screen and (max-width: 48em) {
      .splitter__tab--controllers {
        order: 3;
        width: auto; } }
  .splitter__tab li {
    position: relative;
    display: flex;
    align-items: center;
    height: 2.5rem;
    padding: 0 1rem;
    border-right: 1px solid #e4e4e4;
    cursor: pointer; }
  .splitter__tab li .splitter__tab-count {
    font-size: 0.625rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -0.875rem;
    right: 0.5rem;
    width: 1rem;
    height: 1.5rem;
    border-radius: 0.1875rem;
    z-index: 99; }
  .splitter__tab li:first-child {
    padding-left: 1rem;
    border-left: 1px solid #e4e4e4; }
  .splitter__tab li .splitter__tab-text {
    color: #a4a4a4;
    margin-left: 0.5rem;
    display: none; }
  .splitter__tab li:not(.active) {
    background: #FFFFFF; }
  .splitter__tab li.active .splitter__tab-count {
    font-weight: 700;
    color: #a4a4a4;
    background: #FFFFFF;
    border: 1px solid #e4e4e4; }
  .splitter__tab li.active .icon {
    color: #FFFFFF; }
  .splitter__tab li.active .splitter__tab-text {
    color: #FFFFFF; }
  .splitter__content {
    height: 100%;
    background: #FFFFFF; }

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%; }

.gutter.gutter-horizontal {
  background-image: url("grips/vertical.png");
  cursor: col-resize; }

.gutter.gutter-vertical {
  background-image: url("grips/horizontal.png");
  cursor: row-resize; }

.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII="); }

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg=="); }

.split {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.split,
.gutter.gutter-horizontal {
  float: left; }

.split {
  overflow: hidden; }

.loading {
  display: flex;
  justify-content: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin-left: 3.125rem;
  z-index: 9999;
  background: #ffffff; }
  .loading--close {
    display: none; }
  .loading__loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid #a4a4a4;
    top: 50%;
    animation: loader 2s infinite ease; }
  .loading__loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #a4a4a4;
    animation: loader-inner 2s infinite ease-in; }
  .loading__text {
    position: absolute;
    top: calc(50% + 45px);
    left: 50%;
    color: #a4a4a4;
    transform: translateX(-50%); }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(180deg); }
  50% {
    transform: rotate(180deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader-inner {
  0% {
    height: 0%; }
  25% {
    height: 0%; }
  50% {
    height: 100%; }
  75% {
    height: 100%; }
  100% {
    height: 0%; } }

.accordion {
  margin: auto;
  max-width: 25rem;
  width: 100%; }

.accordion input {
  display: none; }

.box {
  position: relative;
  background: white;
  height: 2rem;
  transition: all .15s ease-in-out; }

.box::before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none; }

.box-header {
  background: #144672;
  z-index: 100;
  cursor: initial;
  padding: 0.625rem 0rem;
  margin-bottom: 0.75rem; }

.box-header .box-title {
  margin: 0;
  font-weight: normal;
  font-size: var(--text-sm);
  color: #ffffff;
  cursor: initial; }

.box-title {
  width: calc(100% - 40px);
  height: 2rem;
  line-height: 2rem;
  padding: 0 1.25rem;
  font-size: var(--text-xs);
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.box-content {
  width: calc(100% - 40px);
  padding: 0.9375rem 1.25rem;
  font-size: var(--text-sm);
  color: rgba(0, 0, 0, 0.54);
  display: none; }

.box-close {
  position: absolute;
  height: 2rem;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none; }

input:checked + .box {
  height: auto;
  margin: var(--text-sm) 0; }

input:checked + .box .box-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.18); }

input:checked + .box .box-content,
input:checked + .box .box-close {
  display: inline-block; }

.arrows section .box-title {
  padding-left: 2.75rem;
  width: calc(100% - 64px); }

.arrows section .box-title:before {
  position: absolute;
  display: block;
  content: '\203a';
  font-size: var(--text-sm);
  left: 1.25rem;
  top: -0.125rem;
  transition: transform .15s ease-in-out;
  color: rgba(0, 0, 0, 0.54); }

input:checked + section.box .box-title:before {
  transform: rotate(90deg); }

.btn,
.btn:link,
.btn:visited {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-sm);
  color: #FFFFFF;
  height: 2.875rem;
  min-width: 7.5rem;
  border-radius: 0;
  border: 1px solid transparent;
  padding: 0 1.5rem;
  background: #144672;
  cursor: pointer; }

.btn--secondary,
.btn--secondary:link,
.btn--secondary:visited {
  color: #144672;
  background: transparent;
  border: 1px solid #144672; }

.btn--block,
.btn--block:link,
.btn--block:visited {
  display: flex;
  width: 100%; }

.btn--mb {
  margin-bottom: 0.75rem; }

.input-group--cta {
  margin-top: 3rem; }

.input {
  display: block;
  font-size: var(--text-xs);
  width: 100%;
  height: 2.875rem;
  border: none;
  border: 1px solid #dbdbdb;
  outline: none;
  padding: 0 1rem;
  border-radius: 0.25rem; }
  .input::placeholder {
    font-size: var(--text-xs); }
  .input__label {
    display: inline-block;
    font-size: var(--text-xs);
    color: #144672;
    margin-bottom: 0.5rem; }
  .input__label--required, .input__label--note {
    font-size: 0.6875rem;
    margin-left: 0.25rem; }
  .input__label--required {
    color: #e64a5f; }
  .input__label--note {
    display: block;
    color: #45bbf0; }
  .input:read-only, .input:disabled {
    color: #757575; }
  .input:focus {
    border-color: #45bbf0; }
  .input-margin {
    margin-bottom: 1.5rem; }

textarea.input {
  height: 9.375rem; }

input[type="color"].input {
  width: 3.125rem;
  padding: 0;
  border: 0;
  cursor: pointer; }

.input-flag {
  position: relative;
  display: inline-block; }
  .input-flag label {
    display: inline-block;
    cursor: pointer; }
  .input-flag label svg {
    filter: grayscale(100%);
    opacity: 0.6;
    transition: all 0.1s linear; }
  .input-flag input {
    position: absolute;
    opacity: 0;
    visibility: hidden; }
  .input-flag input:checked + label svg {
    filter: grayscale(0);
    opacity: 1; }

.select {
  position: relative;
  border-radius: 0.3125rem;
  width: 100%;
  overflow: hidden;
  z-index: 999999; }
  .select__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.5rem;
    padding: 0 1rem;
    background: #144672;
    z-index: 3;
    cursor: pointer; }
  .select__label {
    display: flex;
    align-items: center; }
  .select__label .icon {
    color: #fbfbfb; }
  .select__label-wrapper {
    display: flex;
    align-items: center;
    height: 2.25rem;
    margin-right: 1rem;
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }
  .select__text {
    font-size: var(--text-xs);
    font-weight: 500;
    color: #fbfbfb; }
  .select__icon {
    color: #fbfbfb; }
  .select__body {
    position: absolute;
    left: 0;
    width: 100%;
    box-shadow: 2px 5px 6px 0px #72727236;
    z-index: 999999;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-50px); }
  .select__search {
    font-size: var(--text-xs);
    height: 2.5rem;
    border: none;
    border-left: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    border-bottom: 1px solid #dbdbdb;
    outline: none;
    padding: 0 0.5rem;
    width: 100%;
    background: #ffffff; }
  .select__list {
    width: 100%;
    max-height: 13.0625rem;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    border-left: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
    box-shadow: 2px 5px 4px 0 #7272729c; }
    .select__list::-webkit-scrollbar {
      width: 5px; }
    .select__list::-webkit-scrollbar-thumb {
      background: #144672; }
    .select__list::-webkit-scrollbar-track {
      background: #ebebeb; }
  .select__list li {
    display: flex;
    align-items: center;
    font-size: var(--text-xs);
    height: 2.375rem;
    cursor: pointer;
    padding: 0 0.5rem;
    background: #ffffff;
    border-left: 2px solid transparent;
    transition: all 0.2s ease-in-out; }
  .select__list li:nth-child(odd) {
    background: #fafafa; }
  .select__list li:hover {
    padding-left: 1rem;
    border-color: #dbdbdb; }
  .select__list .icon {
    margin-right: 0.75rem; }
  .select.js-select--open {
    overflow: visible; }
    .select.js-select--open .select__body {
      pointer-events: auto;
      visibility: visible;
      opacity: 1;
      transform: translateY(0px);
      transition: opacity 0.2s linear, transform 0.3s cubic-bezier(0, 0.37, 0, 1.23); }
    .select.js-select--open .select__icon {
      transform: rotate(180deg); }

.input__group--search {
  position: relative; }
  .input__group--search .input {
    min-width: 12.5rem;
    height: 2.5rem;
    border: none;
    border-bottom: 1px solid #dbdbdb;
    outline: none;
    padding: 0 1rem;
    border-radius: 0; }
  .input__group--search .input::placeholder {
    font-size: var(--text-xs); }
  .input__group--search .input__icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
    width: 2.25rem;
    border: none;
    padding: 0;
    margin: 0 0.5rem 0 0;
    cursor: pointer; }
  .input__group--search .input__icon svg {
    color: #dbdbdb;
    width: 2.25rem; }

.checkbox-tab {
  display: block;
  position: relative;
  cursor: pointer;
  background: #fbfbfb;
  margin-bottom: 0.75rem; }
  .checkbox-tab__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    overflow: hidden; }
  .checkbox-tab__wrapper::before {
    content: "";
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4.375rem;
    background: #2ecc71;
    opacity: 0.5;
    transform: scale(1.5);
    z-index: 99; }
  .checkbox-tab__wrapper::after {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2732%27%20height%3D%2732%27%20fill%3D%27%23FFFFFF%27%20viewBox%3D%270%200%20512%20512%27%3E%3Cpath%20d%3D%27M369.164%2C174.77a20%2C20%2C0%2C0%2C1%2C0%2C28.285L234.992%2C337.231a20%2C20%2C0%2C0%2C1-28.285%2C0l-63.871-63.875a20%2C20%2C0%2C0%2C1%2C28.281-28.281l49.73%2C49.731L340.878%2C174.77a20%2C20%2C0%2C0%2C1%2C28.285%2C0ZM512%2C256c0%2C141.5-114.516%2C256-256%2C256C114.5%2C512%2C0%2C397.484%2C0%2C256%2C0%2C114.5%2C114.516%2C0%2C256%2C0%2C397.5%2C0%2C512%2C114.516%2C512%2C256Zm-40%2C0A216%2C216%2C0%2C1%2C0%2C256%2C472%2C215.88%2C215.88%2C0%2C0%2C0%2C472%2C256Zm0%2C0%27%20transform%3D%27translate%280%200%29%27%2F%3E%3C%2Fsvg%3E");
    visibility: hidden;
    position: absolute;
    top: 1.1875rem;
    left: 1.1875rem;
    height: 2rem;
    width: 2rem;
    transform: scale(1.5);
    z-index: 999; }
  .checkbox-tab__images {
    width: 4.375rem;
    height: 4.375rem;
    object-fit: cover; }
  .checkbox-tab__text {
    font-size: var(--text-xs);
    width: 100%;
    text-align: center; }
  .checkbox-tab input {
    position: absolute;
    pointer-events: none;
    visibility: hidden; }
  input:checked + .checkbox-tab__wrapper {
    border-color: #2ecc71; }
  input:checked + .checkbox-tab__wrapper::before,
  input:checked + .checkbox-tab__wrapper::after {
    visibility: visible;
    transform: scale(1);
    transition: transform 0.1s ease-in-out; }
  input:checked + .checkbox-tab__wrapper img {
    filter: grayscale(100%); }
  .checkbox-tab:hover .checkbox-tab__wrapper {
    border-color: #2ecc71;
    transition: all 0.2s linear; }

.toggle {
  display: flex;
  align-items: center; }
  .toggle input {
    opacity: 0;
    position: absolute; }
  input:checked + .toggle__icon:before {
    background: #2ecc71;
    -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1); }
  input:checked + .toggle__icon:after {
    left: 19px; }
  input + .toggle__icon {
    position: relative;
    display: inline-block;
    user-select: none;
    -moz-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    -webkit-tap-highlight-color: transparent;
    height: 20px;
    width: 35px;
    border: 1px solid #e4e4e4;
    border-radius: 60px; }
    input + .toggle__icon:before {
      content: "";
      position: absolute;
      display: block;
      -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      height: 20px;
      width: 35px;
      top: 0;
      left: 0;
      border-radius: 30px; }
    input + .toggle__icon:after {
      content: "";
      position: absolute;
      display: block;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
      -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      background: #f5f5f5;
      height: 18px;
      width: 18px;
      top: 0.5px;
      left: 0px;
      border-radius: 60px; }
  .toggle__icon {
    cursor: pointer; }
  .toggle__text {
    font-size: var(--text-xs);
    margin-left: 0.75rem; }

.checkbox {
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  /* accessibility */ }
  .checkbox [type="checkbox"]:not(:checked),
  .checkbox [type="radio"]:not(:checked),
  .checkbox [type="checkbox"]:checked,
  .checkbox [type="radio"]:checked {
    position: absolute;
    left: -9999px; }
  .checkbox [type="checkbox"]:not(:checked) + label,
  .checkbox [type="radio"]:not(:checked) + label,
  .checkbox [type="checkbox"]:checked + label,
  .checkbox [type="radio"]:checked + label {
    position: relative;
    padding-left: 2rem;
    cursor: pointer; }
  .checkbox [type="checkbox"]:not(:checked) + label:before,
  .checkbox [type="radio"]:not(:checked) + label:before,
  .checkbox [type="checkbox"]:checked + label:before,
  .checkbox [type="radio"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #e4e4e4;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }
  .checkbox [type="checkbox"]:not(:checked) + label:after,
  .checkbox [type="radio"]:not(:checked) + label:after,
  .checkbox [type="checkbox"]:checked + label:after,
  .checkbox [type="radio"]:checked + label:after {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27%23144672%27%20width%3D%2710px%27%20viewBox%3D%270%200%2078.37%2057.484%27%3E%20%3Cg%20transform%3D%27translate%280%20-10.443%29%27%3E%20%3Cpath%20d%3D%27M78.049%2C19.015%2C29.458%2C67.606a1.094%2C1.094%2C0%2C0%2C1-1.548%2C0L.32%2C40.015a1.094%2C1.094%2C0%2C0%2C1%2C0-1.547l6.7-6.7a1.1%2C1.1%2C0%2C0%2C1%2C1.548%2C0L28.685%2C51.876%2C69.8%2C10.763a1.1%2C1.1%2C0%2C0%2C1%2C1.548%2C0l6.7%2C6.7A1.094%2C1.094%2C0%2C0%2C1%2C78.049%2C19.015Z%27%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -0.0625rem;
    left: 0.03125rem;
    width: 1.25rem;
    height: 1.25rem; }
  .checkbox [type="checkbox"]:not(:checked) + label:after,
  .checkbox [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  .checkbox [type="checkbox"]:checked + label:after,
  .checkbox [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1); }
  .checkbox [type="checkbox"]:disabled:not(:checked) + label:before,
  .checkbox [type="radio"]:disabled:not(:checked) + label:before,
  .checkbox [type="checkbox"]:disabled:checked + label:before,
  .checkbox [type="radio"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #144672;
    background-color: #144672; }
  .checkbox [type="checkbox"]:disabled:checked + label:after,
  .checkbox [type="radio"]:disabled:checked + label:after {
    color: #144672; }
  .checkbox [type="checkbox"]:disabled + label,
  .checkbox [type="radio"]:disabled + label {
    color: #144672; }
  .checkbox [type="checkbox"]:checked:focus + label:before,
  .checkbox [type="radio"]:checked:focus + label:before,
  .checkbox [type="checkbox"]:not(:checked):focus + label:before,
  .checkbox [type="radio"]:not(:checked):focus + label:before {
    border: 2px solid #144672; }
  .checkbox [type="checkbox"]:checked + label:before,
  .checkbox [type="radio"]:checked + label:before {
    border-color: #144672; }

.table {
  width: 100%; }
  .table__wrapper {
    position: relative;
    overflow-y: scroll;
    overflow-x: scroll;
    height: calc(100% - 327px);
    background: #FFFFFF;
    z-index: 99;
    width: 100%; }
    @media only screen and (max-width: 48em) {
      .table__wrapper {
        height: calc(100% - 408px); } }
    .table__wrapper::-webkit-scrollbar {
      width: 0.3125rem;
      height: 0.3125rem; }
    .table__wrapper::-webkit-scrollbar-thumb {
      background: #144672; }
    .table__wrapper::-webkit-scrollbar-track {
      background: #ebebeb; }
  .table__sticky {
    position: sticky;
    top: 0; }
  .table th {
    font-size: var(--text-xs);
    font-weight: 500;
    color: #FFFFFF;
    text-align: left;
    background: #144672;
    padding: 1rem 1rem;
    white-space: nowrap; }
  .table th span {
    display: inline-block;
    cursor: pointer; }
  .table th svg {
    margin-left: 0.25rem; }
  .table tr th:last-child,
  .table tr td:last-child {
    text-align: right; }
  .table tr td:not(:last-child) {
    border-right: 2px solid #DDDDDD; }
  .table tbody tr:nth-child(odd) td {
    background: #f8f8f8; }
  .table td {
    font-size: var(--text-xs);
    color: #222222;
    padding: 1rem 1rem;
    white-space: nowrap; }
  .table td.pin {
    text-align: center;
    cursor: pointer; }
  .table td.pin svg {
    color: #144672; }

.pagination {
  text-align: center;
  padding: 1.5rem 0;
  background-color: #FFFFFF;
  z-index: 1; }
  .pagination__list {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none; }
  .pagination__list li:not(:last-child) {
    margin-right: 0.5rem; }
  .pagination__list li a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--text-xs);
    color: #144672;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    text-decoration: none;
    z-index: 2; }
  .pagination__list li a.active {
    color: #FFFFFF;
    background: #144672; }

.building {
  float: left; }

.drooltipLoaderWrapper {
  float: left;
  width: 15px;
  height: 15px;
  padding: 2px;
  left: 50%;
  position: relative;
  transform: translateX(-50%); }

.drooltipLoader {
  border-radius: 100%;
  display: block;
  width: 100%;
  height: 100%;
  animation-name: drooltipPreloader;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
  .drooltipLoader.stop {
    animation: none;
    transform: scale(0); }

.drooltip {
  position: absolute;
  padding: 5px 20px;
  font-size: var(--text-xs);
  color: #fff;
  z-index: 99999;
  max-width: 18.75rem;
  transform: translateY(-8px);
  transform-origin: center 100%;
  opacity: 0; }
  .drooltip .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    box-shadow: 0 1px 42px -3px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    transform-origin: inherit; }
  .drooltip .content {
    position: relative;
    float: left;
    margin-left: 0;
    width: 100%;
    z-index: 1;
    transform-origin: inherit;
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
    .drooltip .content.showDynamic {
      opacity: 0; }
  .drooltip.drooltipMaterial {
    transform-origin: center center; }
    .drooltip.drooltipMaterial .bg {
      height: 0;
      -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      box-shadow: none;
      transform: scale(0); }
    .drooltip.drooltipMaterial .content {
      -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      transform: scale(0) translateY(20px);
      opacity: 0; }
    .drooltip.drooltipMaterial.animate .bg {
      height: 100%;
      box-shadow: 0 1px 42px -3px rgba(0, 0, 0, 0.3);
      transform: scale(1); }
    .drooltip.drooltipMaterial.animate .content {
      transform: scale(1) translateY(0);
      opacity: 1; }
  .drooltip.loaded {
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1; }
  .drooltip.hideTooltip {
    display: none;
    opacity: 0;
    cursor-pointer: none;
    pointer-events: none; }
    .drooltip.hideTooltip.fake {
      display: block;
      opacity: 0;
      pointer-events: none;
      cursor: none;
      transition: none; }
  .drooltip.drooltipBounce {
    animation-name: drooltipBounce;
    animation-duration: 0.2s;
    animation-fill-mode: forwards; }
    .drooltip.drooltipBounce.out {
      animation-name: drooltipBounceOut; }
  .drooltip.drooltipFloat {
    animation-name: drooltipFloatVertical;
    animation-duration: 2s;
    animation-iteration-count: infinite; }
    .drooltip.drooltipFloat.right, .drooltip.drooltipFloat.left {
      animation-name: drooltipFloatHorizontal; }
  .drooltip.drooltipFade {
    animation-name: drooltipFade;
    animation-duration: 0.2s;
    animation-fill-mode: forwards; }
    .drooltip.drooltipFade.out {
      animation-name: drooltipFadeOut; }
  .drooltip:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    border: 6px solid transparent;
    border-top-color: inherit;
    /* left: 255px; */
    z-index: 2;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.2s ease-in-out; }
  .drooltip.showArrow:after {
    bottom: -11px;
    opacity: 1; }
  .drooltip.bottom {
    transform-origin: center 0; }
    .drooltip.bottom:after {
      bottom: auto;
      top: 0;
      border-top-color: transparent;
      border-bottom-color: inherit; }
    .drooltip.bottom.showArrow:after {
      top: -11px; }
  .drooltip.right {
    transform-origin: 0 center; }
    .drooltip.right:after {
      bottom: auto;
      left: 0;
      border-top-color: transparent;
      border-right-color: inherit;
      top: 50%;
      transform: translateY(-50%); }
    .drooltip.right.showArrow:after {
      left: -11px; }
  .drooltip.left {
    transform-origin: 100% center; }
    .drooltip.left:after {
      left: auto;
      bottom: auto;
      border-top-color: transparent;
      border-left-color: inherit;
      top: 50%;
      transform: translateY(-50%);
      right: 0; }
    .drooltip.left.showArrow:after {
      right: -11px; }

@keyframes drooltipPreloader {
  0% {
    transform: scale(0); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

@keyframes drooltipBounce {
  0% {
    transform: scale(0); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

@keyframes drooltipBounceOut {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(0); } }

@keyframes drooltipFade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes drooltipFadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes drooltipFloatVertical {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0px); } }

@keyframes drooltipFloatHorizontal {
  0% {
    transform: translateX(0px); }
  50% {
    transform: translateX(5px); }
  100% {
    transform: translateX(0px); } }

.side-manager {
  position: absolute;
  top: 1.25rem;
  right: 4rem;
  padding: 1.25rem 1rem;
  background: #fbfbfb;
  border-radius: 0.625rem;
  box-shadow: 0 40px 30px rgba(0, 0, 0, 0.15); }
  .side-manager ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .side-manager ul li {
    display: flex;
    align-items: center;
    font-size: 0.625rem; }
  .side-manager ul li:not(:last-child) {
    margin-bottom: 0.75rem; }
  .side-manager ul li span {
    display: inline-block;
    line-height: 1;
    cursor: pointer; }
  .side-manager ul li span:not(:last-child) {
    margin-right: 0.75rem; }
  .side-manager ul li .icon {
    width: 1rem;
    height: 1rem;
    color: #a4a4a4; }
  .side-manager ul li span:last-child .icon {
    width: 0.75rem;
    height: 0.75rem; }

.popup {
  position: absolute;
  padding: 1.25rem 1rem;
  background: #fbfbfb;
  border-radius: 0.625rem;
  box-shadow: 0 40px 30px rgba(0, 0, 0, 0.15); }
  .popup::before {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2716px%27%20viewBox%3D%270%200%207.998%205.002%27%3E%20%3Cpath%20d%3D%27M2.442%2C2H9.549c.331%2C0%2C.576.243.383.482-.155.192-3.279%2C4.032-3.546%2C4.359a.543.543%2C0%2C0%2C1-.77%2C0c-.2-.236-3.336-4.09-3.555-4.369C1.9%2C2.268%2C2.078%2C2%2C2.442%2C2Z%27%20transform%3D%27translate%28-2.002%20-2%29%27%20fill%3D%27%23fbfbfb%27%3E%3C%2Fpath%3E%20%3C%2Fsvg%3E");
    display: block;
    position: absolute;
    left: 50%;
    bottom: -1rem;
    transform: translateX(-50%); }
  .popup ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .popup ul li {
    display: flex;
    align-items: center;
    font-size: 0.875rem; }
  .popup ul li:not(:last-child) {
    margin-bottom: 0.75rem; }
  .popup ul li small {
    font-size: 0.625rem;
    color: #a4a4a4; }
  .popup ul li span {
    display: inline-block;
    line-height: 1;
    cursor: pointer; }
  .popup ul li .icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem; }
