.side-manager {
    position: absolute;
    top: rem(20);
    right: rem(64);
    padding: rem(20) rem(16);
    background: $light-color;
    border-radius: rem(10);
    box-shadow: 0 40px 30px rgba($color: #000000, $alpha: 0.15);

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    ul li {
        display: flex;
        align-items: center;
        font-size: rem(10);
    }

    ul li:not(:last-child) {
        margin-bottom: rem(12);
    }

    ul li span {
        display: inline-block;
        line-height: 1;
        cursor: pointer;
    }

    ul li span:not(:last-child) {
        margin-right: rem(12);
    }

    ul li .icon {
        width: rem(16);
        height: rem(16);
        color: $text-color;
    }

    ul li span:last-child .icon {
        width: rem(12);
        height: rem(12);
    }
}