.popup {
    position: absolute;
    padding: rem(20) rem(16);
    background: $light-color;
    border-radius: rem(10);
    box-shadow: 0 40px 30px rgba($color: #000000, $alpha: 0.15);

    &::before {
        content: inline-svg("<svg xmlns='http://www.w3.org/2000/svg' width='16px' viewBox='0 0 7.998 5.002'> <path d='M2.442,2H9.549c.331,0,.576.243.383.482-.155.192-3.279,4.032-3.546,4.359a.543.543,0,0,1-.77,0c-.2-.236-3.336-4.09-3.555-4.369C1.9,2.268,2.078,2,2.442,2Z' transform='translate(-2.002 -2)' fill='#fbfbfb'></path> </svg>");
        display: block;
        position: absolute;
        left: 50%;
        bottom: rem(-16);
        transform: translateX(-50%);
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    ul li {
        display: flex;
        align-items: center;
        font-size: rem(14);
    }

    ul li:not(:last-child) {
        margin-bottom: rem(12);
    }

    ul li small {
        font-size: rem(10);
        color: $text-color;
    }

    ul li span {
        display: inline-block;
        line-height: 1;
        cursor: pointer;
    }

    ul li .icon {
        width: rem(16);
        height: rem(16);
        margin-right: rem(12);
    }
}