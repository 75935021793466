// *********************
//  Colors
// *********************

$primary-color: #45bbf0;
$secondary-color: #144672;
$text-color: #a4a4a4;

$dark-color: #222222;
$light-color:#fbfbfb;

$border-color: #dbdbdb;

$sidebar-color: #222222;
$header-color: #f2f2f2;
$loading-color: #ffffff;

$valid-color: #2ecc71;
$invalid-color: #e64a5f;
// Primary
.color__text--primary {
    color: $primary-color;
}

.color__bg--primary {
    background: $primary-color;
}

// Secondary
.color__text--secondary {
    color: $secondary-color;
}

.color__bg--secondary {
    background: $secondary-color;
}

// Dark
.color__text--dark {
    color: $dark-color;
}

// Dark
.color__bg--dark {
    background: $dark-color;
}

// Light
.color__text--light {
    color: $light-color;
}

// Light
.color__bg--light {
    background: $light-color;
}

