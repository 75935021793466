.pagination {
    text-align: center;
    padding: rem(24) 0;
    background-color: #FFFFFF;
    z-index: 1;
    
    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &__list li:not(:last-child) {
        margin-right: rem(8);
    }

    &__list li a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--text-xs);
        color: $secondary-color;
        height: rem(28);
        width: rem(28);
        border-radius: 50%;
        text-decoration: none;
        z-index: 2;
    }

    &__list li a.active {
        color: #FFFFFF;
        background: $secondary-color;
    }
}