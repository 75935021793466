.table {
    width: 100%;

    &__wrapper {
        position: relative;
        overflow-y: scroll;
        overflow-x: scroll;
        height: calc(100% - 327px);
        background: #FFFFFF;
        z-index: 99;
        width: 100%;

        @media only screen and (max-width: $medium-device) {
            height: calc(100% - 408px);
           }
        
        &::-webkit-scrollbar {
            width: rem(5);
            height: rem(5);
        }
           
        &::-webkit-scrollbar-thumb {
            background: $secondary-color;
        }
          
        &::-webkit-scrollbar-track {
            background: rgb(235, 235, 235);
        }
    }

    &__sticky {
        position: sticky;
        top: 0;
    }

    th {
        font-size: var(--text-xs);
        font-weight: 500;
        color: #FFFFFF;
        text-align: left;
        background: $secondary-color;
        padding: rem(16) rem(16);
        white-space: nowrap;
    }

    th span {
        display: inline-block;
        cursor: pointer;
    }

    th svg {
        margin-left: rem(4);
    }


    tr th:last-child,
    tr td:last-child {
        text-align: right;
    }

    tr td:not(:last-child) {
        border-right: 2px solid #DDDDDD;
    }

    tbody tr:nth-child(odd) td {
        background: #f8f8f8;
    }

    td {
        font-size: var(--text-xs);
        color: $dark-color;
        padding: rem(16) rem(16);
        white-space: nowrap;
    }

    td.pin {
        text-align: center;
        cursor: pointer;
    }

    td.pin svg {
        color: $secondary-color;
    }


}