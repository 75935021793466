.loading  {
    display: flex;
    justify-content: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin-left: rem(50);
    z-index: 9999;
    background: $loading-color;

    &--close {
        display: none;
    }
    
    &__loader {
        display: inline-block;
        width: 30px;
        height: 30px;
        position: relative;
        border: 4px solid $text-color;
        top: 50%;
        animation: loader 2s infinite ease;
    }
      
    &__loader-inner {
        vertical-align: top;
        display: inline-block;
        width: 100%;
        background-color: $text-color;
        animation: loader-inner 2s infinite ease-in;
    }

    &__text {
        position: absolute;
        top: calc(50% + 45px);
        left: 50%;
        color: $text-color;
        transform: translateX(-50%);
    }
}
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    
    25% {
      transform: rotate(180deg);
    }
    
    50% {
      transform: rotate(180deg);
    }
    
    75% {
      transform: rotate(360deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader-inner {
    0% {
      height: 0%;
    }
    
    25% {
      height: 0%;
    }
    
    50% {
      height: 100%;
    }
    
    75% {
      height: 100%;
    }
    
    100% {
      height: 0%;
    }
  }