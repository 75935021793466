// *********************
// Fonts
// *********************

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-MediumItalic.eot");
    src: url("../fonts/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-MediumItalic.woff2") format("woff2"),
        url("../fonts/Montserrat-MediumItalic.woff") format("woff"),
        url("../fonts/Montserrat-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-BlackItalic.eot");
    src: url("../fonts/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-BlackItalic.woff2") format("woff2"),
        url("../fonts/Montserrat-BlackItalic.woff") format("woff"),
        url("../fonts/Montserrat-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Medium.eot");
    src: url("../fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-Medium.woff2") format("woff2"),
        url("../fonts/Montserrat-Medium.woff") format("woff"),
        url("../fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-BoldItalic.eot");
    src: url("../fonts/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-BoldItalic.woff2") format("woff2"),
        url("../fonts/Montserrat-BoldItalic.woff") format("woff"),
        url("../fonts/Montserrat-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Black.eot");
    src: url("../fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-Black.woff2") format("woff2"),
        url("../fonts/Montserrat-Black.woff") format("woff"),
        url("../fonts/Montserrat-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-LightItalic.eot");
    src: url("../fonts/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-LightItalic.woff2") format("woff2"),
        url("../fonts/Montserrat-LightItalic.woff") format("woff"),
        url("../fonts/Montserrat-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Light.eot");
    src: url("../fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-Light.woff2") format("woff2"),
        url("../fonts/Montserrat-Light.woff") format("woff"),
        url("../fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Bold.eot");
    src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Montserrat-Bold.woff2") format("woff2"),
        url("../fonts/Montserrat-Bold.woff") format("woff"),
        url("../fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

