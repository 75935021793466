.btn,
.btn:link,
.btn:visited {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-sm);
    color: #FFFFFF;
    height: rem(46);
    min-width: rem(120);
    border-radius: 0;
    border: 1px solid transparent;
    padding: 0 rem(24);
    background: $secondary-color;
    cursor: pointer;
}

.btn--secondary,
.btn--secondary:link,
.btn--secondary:visited {
    color: $secondary-color;
    background: transparent;
    border: 1px solid $secondary-color;
}

.btn--block,
.btn--block:link,
.btn--block:visited {
    display: flex;
    width: 100%;
}

.btn--mb {
    margin-bottom: rem(12);
}
