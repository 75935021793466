
// Splitter 
.splitter {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh + 150px);

    .gutter.gutter-vertical {
      z-index: 9;
      background-color: $secondary-color;
    }

    &__header {
        display: flex;
        position: relative;
        background: #FFFFFF;
        flex-wrap: wrap;
        z-index: 9;
    }

    &__header .input__group--search {
      flex-grow: 1;
      min-width: rem(300);
      background: #f6f6f6;

      @media only screen and (max-width: $medium-device) {
        order: 2;
      }
    }

    &__records {
        font-size: var(--text-xs);
        color: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 0 rem(12);
        background: #FFFFFF;
    }

    &__records-count {
        color: $text-color;
        margin-left: rem(12);
    }

    &__tab {
        display: flex;
        list-style: none;
        font-size: var(--text-xs);
        padding: 0;
        margin: 0;
        flex-shrink: 0;
        
        @media only screen and (max-width: $medium-device) {
          justify-content: center;
          width: 100%;
          border-bottom: 1px solid #e4e4e4;
        }
    }

    &__tab:nth-child(1) {
      @media only screen and (max-width: $medium-device) {
        order: 1;
       }
    }
    &__tab:nth-child(2)  {
      @media only screen and (max-width: $medium-device) {
        order: 4;
        width: auto;
       }
    }

    &__tab--controllers {
      margin-left: auto;
      @media only screen and (max-width: $medium-device) {
        order: 3;
        width: auto;
       }
    }

    &__tab li {
        position: relative;
        display: flex;
        align-items: center;
        height: rem(40);
        padding: 0 rem(16);
        border-right: 1px solid #e4e4e4;
        cursor: pointer;
    }

    &__tab li &__tab-count {
        font-size: rem(10);
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: rem(-14);
        right: rem(8);
        width: rem(16);
        height: rem(24);
        border-radius: rem(3);
        z-index: 99;
    }

    &__tab li:first-child {
        padding-left: rem(16);
        border-left: 1px solid #e4e4e4;
    }

    &__tab li &__tab-text {
        color: $text-color;
        margin-left: rem(8);
        display: none;
    }


    &__tab li:not(.active) {
        background: #FFFFFF;
    }

    &__tab li.active &__tab-count {
        font-weight: 700;
        color: $text-color;
        background: #FFFFFF;
        border: 1px solid #e4e4e4;
    }

    &__tab li.active .icon {
        color: #FFFFFF;
    }

    &__tab li.active &__tab-text {
        color: #FFFFFF;

    }

    &__content {
        height: 100%;
        background: #FFFFFF;
    }
  }


.gutter {
  background-color: #eee;

  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('grips/vertical.png');
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-image: url('grips/horizontal.png');
  cursor: row-resize;
}

.gutter.gutter-vertical {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}

.split {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.split,
.gutter.gutter-horizontal {
    float: left;
}

.split {
  overflow: hidden;
}